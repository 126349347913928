var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Добавить товар")])])]),_c('ValidationObserver',{ref:"addProductForm",attrs:{"slim":true}},[_c('md-card-content',[_c('div',{staticClass:"md-layout-item md-large-size-100"},[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Название товара")]),_c('md-input',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Описание")]),_c('md-textarea',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-large-size-100"},[_c('h5',{staticClass:"md-subheading",staticStyle:{"margin-bottom":"15px"}},[_vm._v(" Добавить изображения ")]),_c('ValidationProvider',{ref:"imageValidator",attrs:{"mode":"passive","slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"uploader-wrapper"},[_c('ValidationError',{attrs:{"errors":errors}}),_c('VueUploadMultipleImage',{key:("uploader_" + _vm.uploaderKey),attrs:{"drag-text":"Drag here","browse-text":"Browse","primary-text":"Main image","mark-is-primary-text":"Set as main","popup-text":"This image will be set as main","drop-text":"Drop here","accept":"image/jpeg, image/png, image/jpg","max-image":5,"show-edit":false},on:{"before-remove":_vm.beforeRemove,"mark-is-primary":_vm.replaceImage,"upload-success":_vm.imagesChange}})],1)]}}])})],1),_c('div',{staticClass:"md-layout wrapper"},[_c('div',{staticClass:"md-layout-item md-large-size-50"},[_c('h5',{staticClass:"md-subheading"},[_vm._v("Основные параметры товара")]),_c('div',{staticClass:"row md-layout",staticStyle:{"margin":"20px -15px 0"}},[_c('div',{staticClass:"md-layout-item md-size-30 md-body-2"},[_vm._v(" Размер ")]),_c('div',{staticClass:"md-layout-item md-size-30 md-body-2"},[_vm._v(" Цвет ")]),_c('div',{staticClass:"md-layout-item md-size-20 md-body-2"},[_vm._v(" Кол-во ")]),_c('div',{staticClass:"md-layout-item md-large-size-20"})]),_vm._l((_vm.options),function(option,index){return _c('div',{key:'option_' + index,staticClass:"row md-layout",staticStyle:{"margin":"0 -15px","justify-content":"start"}},[_c('ValidationProvider',{staticClass:"md-layout-item md-size-30",attrs:{"mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('md-input',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(option.size),callback:function ($$v) {_vm.$set(option, "size", $$v)},expression:"option.size"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-30",attrs:{"mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('md-select',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(option.color_id),callback:function ($$v) {_vm.$set(option, "color_id", $$v)},expression:"option.color_id"}},_vm._l((_vm.colors),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id,"placeholder":"Цвет"}},[_c('div',{style:({
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            marginRight: '10px',
                            flexShrink: '0',
                            backgroundColor: item.hex,
                          })}),_c('span',[_vm._v(_vm._s(item.name))])])}),1)],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"md-layout-item md-size-20",attrs:{"mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"blur":function($event){return _vm.updateNewProduct()},"change":function($event){return _vm.onValueChange('quantity', option, $event.target.value)}},model:{value:(option.quantity),callback:function ($$v) {_vm.$set(option, "quantity", $$v)},expression:"option.quantity"}})],1)]}}],null,true)}),(index !== 0)?_c('div',{staticClass:"btn-delete-wrap md-layout-item md-large-size-20"},[_c('md-button',{staticClass:"md-raised md-danger",on:{"click":function($event){return _vm.deleteOption('option', index)}}},[_c('md-icon',[_vm._v("delete")])],1)],1):_vm._e()],1)}),_c('div',{staticClass:"button-wrap"},[_c('md-button',{staticClass:"md-raised md-primary",on:{"click":function($event){return _vm.addNewOption('option')}}},[_vm._v(" Добавить параметр ")])],1),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var reset = ref.reset;
return [_c('div',{staticClass:"treeselect-wrapper"},[_c('ValidationError',{attrs:{"errors":errors}}),_c('Treeselect',{attrs:{"multiple":true,"options":_vm.categories,"flat":true,"auto-deselect-descendants":true,"auto-select-descendants":true,"value-consists-of":"LEAF_PRIORITY","placeholder":"Категории"},on:{"open":reset,"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.categoriesArray),callback:function ($$v) {_vm.categoriesArray=$$v},expression:"categoriesArray"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Поставщик")]),_c('md-select',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.vendorId),callback:function ($$v) {_vm.vendorId=$$v},expression:"vendorId"}},_vm._l((_vm.vendors),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id,"placeholder":"Поставщик"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Теги")]),_c('md-select',{attrs:{"multiple":true},on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.tagsArray),callback:function ($$v) {_vm.tagsArray=$$v},expression:"tagsArray"}},_vm._l((_vm.tags),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id,"placeholder":"Теги"}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1)]}}])}),_c('h5',{staticClass:"md-subheading"},[_vm._v("Стоимость товара")]),_c('ValidationObserver',{ref:"calculateCostForm",attrs:{"slim":true}},[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Цена закупки")]),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"blur":function($event){return _vm.updateNewProduct()},"change":function($event){return _vm.onValueChange(
                          'purchasePrice',
                          null,
                          $event.target.value
                        )}},model:{value:(_vm.purchasePrice),callback:function ($$v) {_vm.purchasePrice=$$v},expression:"purchasePrice"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Валюта")]),_c('md-select',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},_vm._l((_vm.currencies),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.value,"placeholder":"Валюта"}},[_vm._v(" "+_vm._s(item.value)+" ")])}),1)],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Точный вес")]),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"blur":function($event){return _vm.updateNewProduct()},"change":function($event){return _vm.onValueChange('weight', null, $event.target.value)}},model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=$$v},expression:"weight"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Регион")]),_c('md-select',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},_vm._l((_vm.countries),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.name,"placeholder":"Регион"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]}}])}),_c('div',{staticClass:"row md-layout"},[_c('md-field',{staticClass:"md-layout-item md-size-40"},[_c('label',[_vm._v("Рекомендуемая цена")]),_c('md-input',{attrs:{"value":_vm.priceForNewProduct,"disabled":true},on:{"blur":function($event){return _vm.updateNewProduct()}}})],1),(_vm.pickedCurrency && _vm.currency !== 'RUB')?_c('div',{staticClass:"md-layout-item md-size-30"},[_vm._v(" "+_vm._s(("1 " + (_vm.pickedCurrency.value) + " = " + (_vm.pickedCurrency.exchangeRate) + " RUB"))+" ")]):_vm._e(),_c('div',{staticClass:"md-layout-item md-size-30"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.updateCurrency}},[_vm._v(" Обновить курсы валют ")]),_c('md-button',{staticClass:"md-raised md-primary",on:{"click":_vm.calculateCost}},[_vm._v(" Рассчитать ")])],1)],1)],1),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Цена")]),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"blur":function($event){return _vm.updateNewProduct()},"change":function($event){return _vm.onValueChange('price', null, $event.target.value)}},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1)]}}])}),_c('md-field',[_c('label',[_vm._v("Старая цена")]),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"change":function($event){return _vm.onValueChange('oldPrice', null, $event.target.value)}},model:{value:(_vm.oldPrice),callback:function ($$v) {_vm.oldPrice=$$v},expression:"oldPrice"}})],1)],2),_c('div',{staticClass:"md-layout-item md-large-size-50"},[_c('h5',{staticClass:"md-subheading"},[_vm._v("Характеристики товара")]),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Бренд")]),_c('md-select',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.brandId),callback:function ($$v) {_vm.brandId=$$v},expression:"brandId"}},_vm._l((_vm.brands),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id,"placeholder":"Бренд"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Сезон")]),_c('md-select',{attrs:{"multiple":true},on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.seasonsArray),callback:function ($$v) {_vm.seasonsArray=$$v},expression:"seasonsArray"}},_vm._l((_vm.seasons),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id,"placeholder":"Сезон"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]}}])}),_c('div',{staticClass:"column options"},[_c('div',{staticClass:"md-subheading"},[_vm._v("Материалы:")]),_vm._l((_vm.materials),function(material,index){return _c('div',{key:("material_" + index),staticClass:"params md-layout"},[_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-40",attrs:{"mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Материал")]),_c('md-input',{on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(material.name),callback:function ($$v) {_vm.$set(material, "name", $$v)},expression:"material.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"md-layout-item md-large-size-40",attrs:{"mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Значение")]),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"blur":function($event){return _vm.updateNewProduct()},"change":function($event){return _vm.onValueChange(
                            'material',
                            material,
                            $event.target.value
                          )}},model:{value:(material.value),callback:function ($$v) {_vm.$set(material, "value", $$v)},expression:"material.value"}})],1)]}}],null,true)}),(index !== 0)?_c('div',{staticClass:"btn-delete-wrap md-layout-item md-large-size-20"},[_c('md-button',{staticClass:"md-raised md-danger",on:{"click":function($event){return _vm.deleteOption('material', index)}}},[_c('md-icon',[_vm._v("delete")])],1)],1):_vm._e()],1)}),_c('div',{staticClass:"button-wrap"},[_c('md-button',{staticClass:"md-raised md-primary",on:{"click":function($event){return _vm.addNewOption('material')}}},[_vm._v(" Добавить материал ")])],1)],2),_c('h5',{staticClass:"md-subheading"},[_vm._v("Дополнительные параметры")]),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Витрины")]),_c('md-select',{attrs:{"multiple":true},on:{"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.storefrontsArray),callback:function ($$v) {_vm.storefrontsArray=$$v},expression:"storefrontsArray"}},_vm._l((_vm.storefronts),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.id,"placeholder":"Витрины"}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
                          var reset = ref.reset;
return [_c('md-datepicker',{class:{ error: errors.length },attrs:{"md-model-type":String},on:{"md-opened":reset,"blur":function($event){return _vm.updateNewProduct()}},model:{value:(_vm.removalTime),callback:function ($$v) {_vm.removalTime=$$v},expression:"removalTime"}},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("В архив")])],1)]}}])}),_c('md-checkbox',{model:{value:(_vm.isNotActive),callback:function ($$v) {_vm.isNotActive=$$v},expression:"isNotActive"}},[_vm._v(" Снять с публикации ")])],1)]),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.updateNewProduct}},[_vm._v(" Сохранить ")]),_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.addNewProduct}},[_vm._v(" Опубликовать ")]),_c('md-button',{staticClass:"md-raised md-danger",on:{"click":_vm.initProduct}},[_vm._v(" Очистить ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }